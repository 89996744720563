<template>
  <div class="content">
    <div class="row">
      <div class="col-lg-12">
        <div class="col-sm-6 text-left">
          <h2 class="card-title"> MES PRÉPAIEMENTS</h2>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="col-lg-6 alert alert-info">
          Nous vous invitons à créditer /pré-payer votre compte. Le minimum est de 1000€.<br />
          Pour toute question, nous sommes joignables au 04 72 61 26 31 ou par e-mail à contact@weedoit.fr
        </div>
      </div>

      <div class="col-lg-6">
        <card>
          <div class="col-sm-12">

            <h4 class="info-text" style="color: #988fc3">CRÉDITER AVEC UNE CARTE BANCAIRE</h4>

            <base-input label="LISTE DES DOCUMENTS">
              <el-select class="select-primary" placeholder="SÉLECTIONNER LES CRITÈRES" v-model="id_oi"
                @change="getMontantTotal">
                <el-option v-for="document in documents" class="select-primary" v-if="document.paid != 'YES'"
                  :value="document.id_oi" :label="document.numero_oi.toUpperCase()" :key="document.id_oi">
                </el-option>
              </el-select>
            </base-input>
            <form data-vv-scope="ScopeAmount">
              <base-input
                label="Montant (HT) €"
                name="montant"
                :error="getError('ScopeAmount.montant')"
                v-validate="{
                  required: true,
                  min_value: 100
                }"
                v-model="amount"
                type="number"
                :disabled="facture || id_oi || id_clien_leadmarket != 0">
              </base-input>
            </form>
            <base-button class="pull-right" native-type="submit" type="primary" v-on:click="payment"
              :disabled="disableButton">VALIDER</base-button>

          </div>
        </card>
      </div>

      <div class="col-lg-6">
        <card>
          <div class="corps" v-if="state == 'paiement_begin'">
            <div class="form-row">
              <label class="col-md-12">Détails de votre prépaiement :</label>
            </div>
            <div class="form-row">
              <label class="col-md-6">Montant (HT) :</label>
              <label class="col-md-6">{{ amount }} €</label>
            </div>
            <div class="form-row">
              <label class="col-md-6">TVA ({{ (amountTTC > amount) ? '20%' : '0%' }}) :</label>
              <label class="col-md-6">{{ amountTTC - amount }} €</label>
            </div>
            <div class="form-row">
              <label class="col-md-6">Montant à payer (TTC) :</label>
              <label class="col-md-6"><b>{{ amountTTC }} €</b></label>
            </div>
            <br>
            <label>
              <div v-html="message">
              </div>
            </label>
            <br>
          </div>

          <div v-else-if="state == 'payment_completed'">
            <h1>payment completed</h1>
          </div>
        </card>
      </div>
      <div class="col-lg-12" v-if="id_clien_leadmarket">
        <div class="btn-group btn-group-toggle float-left mb-3" data-toggle="buttons">
          <button @click="showAddAmount = true" class="btn btn-round btn-just-icon btn-market">CRÉDITER MON COMPTE WEEDO
            MARKET</button>
        </div>
      </div>
      <div class="col-lg-12">
        <card>
          <div class="card-body">
            <base-input label="mois" class="col-md-3">
              <el-select class="select-primary" clearable filterable
                placeholder="CHOISISSEZ"
                v-model="MounthSelects.Mounth">
                <el-option
                  v-for="option in MounthSelects.MounthList"
                  class="select-primary"
                  :value="option.value"
                  :label="option.label"
                  :key="option.value">
                </el-option>
              </el-select>
            </base-input>
          </div>
        </card>
      </div>
      <div class="col-lg-12">
        <card>
          <div class="card-body">
            <div>
              <div class="row">
                <div class="col-xl-1 col-6">
                  <base-input>
                    <el-select class="select-primary mb-3 pagination-select" v-model="pagination.perPage"
                      placeholder="Per page">
                      <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item"
                        :label="item" :value="item"></el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-xl-8 col-6">
                  <label
                    style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}
                  </label>
                </div>
                <base-input class="col-xl-3 col-sm-6">
                  <el-input
                    type="search"
                    class="mb-3 search-input"
                    clearable
                    prefix-icon="el-icon-search"
                    placeholder="Rechercher"
                    aria-controls="datatables"
                    v-model="searchQuery"
                  >
                  </el-input>
                </base-input>
              </div>
              <el-table :data="queriedData" show-summary :summary-method="getSummaries" v-loading="$apollo.queries.MyPrepayments.loading || $apollo.queries.listOi.loading">
                <el-table-column prop="id" label="Ref" class="table-striped" min-width="165" sortable>
                  <template scope="props">{{ props.row.id }}</template>
                </el-table-column>

                <el-table-column prop="paymentDate" label="Date" class="table-striped" min-width="195" show-overflow-tooltip sortable>
                  <template scope="props">{{ props.row.paymentDate }}</template>
                </el-table-column>

                <el-table-column prop="montant" label="Montant (HT)" class="table-striped" show-overflow-tooltip min-width="160" sortable>
                  <template scope="props">{{ formatThounsends(props.row.montant, true, 2) + ' €' }}</template>
                </el-table-column>

                <el-table-column prop="source" label="Source" class="table-striped" min-width="150" show-overflow-tooltip sortable>

                    <template scope="scope">
                      <template v-if="scope.row.source === 'code_promo'">
                        CODE PROMO
                      </template>
                      <template v-else-if="scope.row.source === 'devalidation'">
                        DÉVALIDATION
                      </template>
                      <template v-else-if="scope.row.source === 'sogenactif'">
                        CARTE BANCAIRE
                      </template>
                      <template v-else>
                        {{ (!scope.row.source) ? '-' : scope.row.source.toUpperCase() }}
                      </template>
                    </template>
                </el-table-column>

                <el-table-column prop="etat" label="Etat" class="table-striped" min-width="150" show-overflow-tooltip sortable>
                  <template scope="props">{{ paymentEtat(props.row.etat).toUpperCase() }}</template>
                </el-table-column>
                <el-table-column label="Actions" minWidth="110">
                  <template scope="props">
                    <template v-if="props.row.flagmarket">
                      <el-tooltip
                        content="Télécharger le reçu"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                        v-if="props.row.source === 'Carte Bancaire' && props.row.etat === 'acceptee'"
                      >
                        <base-button
                          class="btn-link"
                          type="success"
                          size="sm"
                          @click="DownloadPDF_market(props.row.id)"
                          icon
                        >
                          <i class="far fa-file-alt icon-large"></i>
                        </base-button>
                      </el-tooltip>
                    </template>
                    <template v-else>
                      <template v-if="props.row.paymentapi == 'sogenactif' &&  props.row.etat == 'acceptee'">
                        <el-tooltip content="Télécharger le reçu" effect="light" :open-delay="300" placement="top">
                          <base-button @click.native="handleDownload(props.row)" class="btn-link" type="success" size="sm"
                            icon>
                            <i class="far fa-file-alt icon-large"></i>
                          </base-button>
                        </el-tooltip>
                      </template>
                    </template>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Platforme"
                  :min-width="120"
                  class="table-striped"
                >
                  <template scope="scope">
                    <el-tooltip v-if="scope.row.flagmarket" content="WEEDO MARKET" effect="light" :open-delay="300" placement="top">
                      <span class="btn btn-link btn-default btn-icon btn-sm like">
                        <svg version="1.1" id="Calque_1" fill="#588bbd" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                          viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                          <g>
                            <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                            <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                              c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                              h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                              />
                          </g>
                        </svg>
                      </span>
                    </el-tooltip>
                    <el-tooltip v-else content="WEEDO PERF" effect="light" :open-delay="300" placement="top">
                      <span class="btn btn-link btn-default btn-icon btn-sm like">
                        <svg version="1.1" id="Calque_1" fill="#988fc3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                          viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                          <g>
                            <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                            <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                              c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                              h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                              />
                          </g>
                        </svg>
                      </span>
                    </el-tooltip>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class>
                <p class="card-category">{{ $t('labels.Datatable.Showing') }} {{ from + 1 }}
                  {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }}
                  {{ $t('labels.Datatable.entries') }}</p>
              </div>
              <base-pagination class="pagination-no-border" v-model="pagination.currentPage"
                :per-page="pagination.perPage" :total="total"></base-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>

    <modal :show.sync="showAddAmount" body-classes="p-0" class="modal-listbase">
      <card type="secondary" header-classes="pb-6" body-classes="px-lg-6 py-lg-6" class="border-0 mb-0">

        <template slot="header">
          <el-tooltip content="Quitter" effect="light" :open-delay="300" placement="top">
            <base-button @click.native="showAddAmount = false" class="btn-link float-right" type="danger" size="sm"
              icon>
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </el-tooltip>
        </template>

        <base-alert type="info" dismissible>
          <p style="color: white; font-size: small;">
            Pour commander vos leads, nous vous invitons à créditer /pré-payer votre compte. Le minimum est de 1000€.<br>
            La consommation de ce crédit et l’édition des factures se fera à la commande de vos leads.<br>
            Si vous bénéficiez d’un code promo, vous devez au préalable créditer votre compte d’un montant minimum de
            1000€ pour pouvoir l’utiliser.<br>
            Pour toute question, nous sommes joignables au 04 72 61 26 31 ou par e-mail à contact@leadmarket.fr<br>
          </p>
        </base-alert>

        <h4>CRÉDITER AVEC UNE CARTE BANCAIRE</h4>
        <base-table :data="[{titre:'1'}]" thead-classes="text-primary">
          <template slot-scope="{ row }">
            <td>
              <label>MONTANT </label>
            </td>
            <td>
              <form data-vv-scope="ScopeCard">
                <base-input type="number" v-model="Montant_market" :error="getError('ScopeCard.montant')" name="montant"
                  v-validate="{required: true, min_value: (['3058'].includes(utilisateur_id+'')) ? 1 : (['3408', '2718','3434','2136', '3485', '3467', '718', '2039', '3423', '3494', '1984', '3482', '1205', '2934'].includes(utilisateur_id+'')) ? 500 : (['3230'].includes(utilisateur_id+'')) ? 600 :1000}"></base-input>
              </form>
            </td>
            <td class="td-actions text-right">
              <base-button type="market" @click="AddCreditByCard()">
                VALIDER
              </base-button>
            </td>
          </template>
        </base-table>

        <hr class="my-4">

        <h4>CRÉDITER AVEC UN CODE PROMO</h4>
        <base-table :data="[{titre:'1'}]" thead-classes="text-primary">
          <template slot-scope="{ row }">
            <td>
              <label>CODE PROMO</label>
            </td>
            <td>
              <form data-vv-scope="ScopeCoupon">
                <base-input v-model="Coupon" :error="getError('ScopeCoupon.code promo')" name="code promo"
                  v-validate="'required'"></base-input>
              </form>
            </td>
            <td class="td-actions text-right">
              <base-button type="market" @click="AddCreditByCoupon()">
                VALIDER
              </base-button>
            </td>
          </template>
        </base-table>
      </card>
    </modal>
    <!-- mondal credité mon compte Lead Market-->

    <!-- form sogenactif -->
    <form method="post" id="form-sogenactif" :action="sogenactif_url">
      <input type="hidden" name="data" size="300" :value="sogenactif_data">
      <input type="hidden" name="InterfaceVersion" size="300" :value="sogenactif_interfaceVersion">
      <input type="hidden" name="seal" size="300" :value="sogenactif_seal">
      <input type="submit" id="submit-form-sogenactif" value="v" v-show="false">
    </form>
    <!-- form sogenactif -->

  </div>
</template>

<script>
  import gql from "graphql-tag";
  import { BaseAlert } from "src/components";
  import { BaseRadio } from "src/components/index";
  import { Popover } from "element-ui";
  import { Select, Option } from "element-ui";
  import { Table, TableColumn } from "element-ui";
  import { BasePagination } from "src/components";
  import { Modal, BaseTable } from 'src/components'
  import swal from 'sweetalert2'


  export default {
    components: {
      BaseRadio,
      BaseAlert,
      [Select.name]: Select,
      [Option.name]: Option,
      [Popover.name]: Popover,
      BasePagination,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      Modal,
      BaseTable
    },
    apollo: {
      MyPrepayments: {
        client: 'MARKET',
        query: require("src/graphql/leadmarket/Annonceur/MyPrepayments.gql"),
        variables() {
          return {
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id
          }
        },
        result({ data, loader, networkStatus }) {
          data.mesPrepaiements.forEach(prepaie => {
            this.tableData.push(
              {
                id: prepaie.pk_prepaiment,
                paymentDate: prepaie.paymentDate,
                montant:prepaie.paymentAmount,
                source: prepaie.paymentapi,
                etat: prepaie.etat,
                flagmarket: true
              }
            )
          });
        },
        fetchPolicy: 'network-only',
        update: data => data.mesPrepaiements
      },
      listOi:
      {
        client: 'AUTH',
        query: gql` query ($id_annonceur: Int) {
                  listDocuments (id_annonceur: $id_annonceur) {
                      id_oi
                      date_creation_oi
                      numero_oi
                      status
                      montant_total
                      signatureUrl
                      transactionId
                      paid
                  },
                  listPrepays(id_annonceur: $id_annonceur){
                      Prepaiements{
                        id
                        id_utilisateur
                        id_oi_globale
                        transaction_id
                        paid
                        etat
                        source
                        montant
                        paymentDate
                        paymentapi
                      }
                      montantTotal
                  }
              }`,
        fetchPolicy: 'no-cache',
        variables() {
          return {
            id_annonceur: JSON.parse(localStorage.getItem('utilisateur')).id
          }
        },
        result({ data, loader, networkStatus }) {
          this.documents = data.listDocuments
          this.tableData = this.tableData.concat(data.listPrepays[0].Prepaiements || [])
          // this.montantTotal = data.listPrepays[0].montantTotal
        },
        update: data => data.listDocuments
      },
    },
    data() {
      return {
        sogenactif_data: "",
        sogenactif_interfaceVersion: "",
        sogenactif_seal: "",
        sogenactif_url: "",
        amountTTC: 0,
        MounthSelects: {
          Mounth: "",
          MounthList: [
            { label: 'JANVIER', value: '01'},
            { label: 'FÉVRIER', value: '02'},
            { label: 'MARS', value: '03'},
            { label: 'AVRIL', value: '04'},
            { label: 'MAI', value: '05'},
            { label: 'JUIN', value: '06'},
            { label: 'JUILLET', value: '07'},
            { label: 'AOÛT', value: '08'},
            { label: 'SEPTEMBRE', value: '09'},
            { label: 'OCTOBRE', value: '10'},
            { label: 'NOVEMBRE', value: '11'},
            { label: 'DÉCEMBRE', value: '12'}
          ]
        },
        documents: [],
        tableData: [],
        id_oi: null,
        utilisateur_id: JSON.parse(localStorage.getItem('utilisateur')).id,
        amount: null,
        action: 'sogenactif',
        state: false,
        message: '',
        total: 0,
        pagination: {
          perPage: 100,
          currentPage: 1,
          perPageOptions: [50, 100, 250, 500],
          total: 0
        },
        PDFURL: '',
        // ShowPDF: false,
        disableButton: false,
        montantTotal: null,
        facture: null,
        showAddAmount: false,
        Montant_market: null,
        Coupon: null,
        message_market: '',
        searchQuery: '',
        id_clien_leadmarket: JSON.parse(localStorage.getItem('utilisateur')).id_clien_leadmarket
      }
    },
    methods: {
      async payment() {
        let validate
        await this.$validator.validateAll("ScopeAmount").then(isValid => {
          validate = isValid
        })
        if (!validate)
          return

        let formData = new FormData()
        if (this.facture != null) {
          formData.append("action", "facture")
          formData.append("id", (this.facture.ref_perf) ? this.facture.ref_perf : "")
          formData.append("id_coreg", (this.facture.ref_coreg) ? this.facture.ref_coreg : "")
        }
        else if (this.id_oi != null) {
          formData.append("action", "document")
          formData.append("id", this.id_oi)
        }
        else {
          formData.append("action", "libre")
        }
        formData.append("amount", this.amount)
        formData.append("utilisateur_id", this.utilisateur_id)

        const url_api = 'https://api-auth-plf.wee-do-it.net/api/v1/paymentgetway/sogenactif/request'
        let accessToken = localStorage.getItem("token_jwt")
        this.axios.post(url_api, formData,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'authorization': `Bearer ${accessToken}`
            }
          })
          .then(response => {
            this.redirectSogenactif(response.data)
          })
          .catch(() => {
            console.log('error occured')
          })
      },
      getMontantTotal: function () {
        this.facture = null
        this.documents.forEach(document => {
          if (this.id_oi == document.id_oi) {
            this.amount = document.montant_total
            this.id_oi = document.id_oi
            this.disableButton = false
          }
        });
      },
      formatThounsends: function (value, fixe = false, number = 0) {
        if (value) {
          if (fixe) {
            value = value.toFixed(number);
          }
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        }
        return 0
      },
      handleDownload(row) {
        const url_api = "https://api-auth-plf.wee-do-it.net/api/v1/generatePdfPrepaiement";
        //const url_api = "http://localhost:9000/api/v1/generatePdfPrepaiement";
        let accessToken = localStorage.getItem("token_jwt");
        let formData = new FormData()
        formData.append('prepaiement_id', row.id)
        this.axios.post(url_api, formData, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded', authorization: `Bearer ${accessToken}` },
          responseType: "blob"
        }).then(function (response) {
          let blob = new Blob([response.data], { type: 'application/pdf' })
          const filename = "reçu-" + row.transaction_id;
          let link = document.createElement('a')
          //this.ShowPDF = true
          this.PDFURL = window.URL.createObjectURL(blob)

          link.href = this.PDFURL;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();

        }.bind(this))
          .catch(function (error) {
            console.log(error.message);
          })
      },
      paymentEtat(etat) {
        if (etat == "refusee") {
          return "Paiement Refusé";
        } else if (etat == "acceptee") {
          return "Paiement Confirmé";
        } else return "-";
      },
      getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = "TOTAL";
            return;
          } else if (index === 1) {
            sums[index] = "";
            return;
          } else if (index === 2) {
            sums[index] = this.formatThounsends(this.montantTotal, true, 2) + ' €';
            return;
          } else {
            sums[index] = "";
            return;
          }
        });
        return sums;
      },
      async AddCreditByCard() {
        let validate
        await this.$validator.validateAll("ScopeCard").then(isValid => {
          validate = isValid
        })
        if (!validate)
          return

        let formData = new FormData()

        formData.append("action", "leadmarket_prepaiement")
        formData.append("amount", this.Montant_market)
        formData.append("utilisateur_id", JSON.parse(localStorage.getItem('utilisateur')).id)

        const url_api = 'https://api-auth-plf.wee-do-it.net/api/v1/paymentgetway/sogenactif/request'
        let accessToken = localStorage.getItem("token_jwt")
        this.axios.post(url_api, formData,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'authorization': `Bearer ${accessToken}`
            }
          })
          .then(response => {
            this.redirectSogenactif(response.data)
          })
          .catch(error => {
            console.log(error)
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
          })

      },
      redirectSogenactif(data) {

        this.sogenactif_data = data.data
        this.sogenactif_interfaceVersion = data.interfaceVersion
        this.sogenactif_seal = data.seal
        this.sogenactif_url = data.sogenactifURL

        swal.fire({
          title: 'Êtes-vous sûr?',
          text: "Vous allez être redirigés pour compléter votre pré-payment !",
          width: 400,
          showCancelButton: true,
          confirmButtonColor: '#988fc3',
          cancelButtonColor: '#27293D',
          confirmButtonText: 'Valider',
          cancelButtonText: 'Annuler'
        }).then((result) => {
          if (result.value) {
            document.getElementById("submit-form-sogenactif").click();
          }
        })

      },
      async AddCreditByCoupon() {
        let validate
        await this.$validator.validateAll("ScopeCoupon").then(isValid => {
          validate = isValid
        })
        if (!validate)
          return

        if (localStorage.getItem("compteur") === null) {
          localStorage.setItem("compteur", 0)
        }

        if (parseInt(localStorage.getItem("compteur")) > 5) {
          this.$notify({ type: 'warning', message: "Vous avez depassé le nombre de tentatives d'utilisation de codes promo" })
          this.showAddAmount = false
          return
        }
        await this.$apollo.mutate({
          client: 'MARKET',
          mutation: require("src/graphql/leadmarket/Annonceur/mutation/prepaiementByCodepromo.gql"),
          variables: {
            id_utilisateur: JSON.parse(localStorage.getItem('utilisateur')).id,
            code: this.Coupon,
            compteur: parseInt(localStorage.getItem("compteur"))
          },
        }).then(response => {
          if (response.data.prepaiementByCodepromo.state == false) {
            this.$notify({ type: 'warning', message: "Code promo saisi est incorrect" })
          }
          else {
            this.$notify({ type: 'success', message: "Code promo correct" })
            this.showAddAmount = false
            this.$apollo.queries.MyPrepayments.refetch({
              utilisateur_id: JSON.parse(localStorage.getItem('utilisateur')).id
            })
          }
        }).catch(error => {
          this.$notify({ type: 'warning', message: "erreur" })
        })
        localStorage.setItem("compteur", parseInt(localStorage.getItem("compteur")) + 1)
      },
      DownloadPDF_market(ref) {
        const url_api = "https://apileadmarket.wee-do-it.net/api/generate-prepaiement-recu";
        let accessToken = localStorage.getItem("token_jwt");
        let formData = new FormData()
        formData.append('id_prepaiement', ref)
        formData.append('id_utilisateur', JSON.parse(localStorage.getItem('utilisateur')).id)
        this.axios.post(url_api,formData , {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            authorization: `Bearer ${accessToken}`
          },
          responseType: "blob"
        }).then(function(response) {

          const filename = "reçu-" + ref +".pdf";
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();

        }.bind(this))
        .catch(function (error) {
          console.log(error.message);
        })
      },
      getError(fieldName) {
        return this.errors.first(fieldName);
      }
    },
    computed: {
      queriedData() {
        let result = [];

        try {
          result = this.tableData.filter((pay) => {
            if  (pay.paymentDate == null)
              pay.paymentDate = ""
            return ((pay.id+"").match(this.searchQuery) ||
                    pay.paymentDate.match(this.searchQuery) ||
                    (pay.etat+"").match(this.searchQuery) ||
                    (pay.source+"").match(this.searchQuery) ||
                    (pay.montant+"").match(this.searchQuery)) &&
                    pay.paymentDate.split("-")[1].match(this.MounthSelects.Mounth)
          })
          this.total = result.length
          let mt_total = 0
          result.slice(this.from, this.total).forEach(element => {
            mt_total += parseFloat(element.montant)
          });
          this.montantTotal = mt_total
          return result.slice(this.from, this.to);
        } catch (error) {
          console.log(error)
          return null
        }
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      }
    },
    created() {
      var response = typeof this.$route.query.response !== typeof undefined;
      if (response) {
        if (this.$route.query.response == 'paiement_completed') {
          this.$notify({ type: 'success', verticalAlign: 'top', horizontalAlign: 'right', message: 'Votre paiement à été traité avec succès.', duration: 10000 });
        } else if (this.$route.query.response == 'paiement_canceled') {
          this.$notify({ type: 'warning', verticalAlign: 'top', horizontalAlign: 'right', message: 'Votre paiement a été refusé ou vous avez annulé la transaction!', duration: 10000 });
        }
        this.$router.replace('/mes-prepaiement')
      }

      if (JSON.parse(localStorage.getItem('facture')) != null) {
        this.facture = JSON.parse(localStorage.getItem('facture'))

        if (this.facture.flag == "multi")
          this.amount = (this.facture.montant_ttc_coreg + this.facture.montant_ttc_perf).toFixed(1)
        else if (this.facture.flag == "perf")
          this.amount = this.facture.montant_ttc_perf.toFixed(1)
        else
          this.amount = this.facture.montant_ttc_coreg.toFixed(1)

        this.disableButton = false
        localStorage.removeItem('facture')
      }
    },
    watch: {
      "documents": function (val) {
        if (localStorage.getItem('id_oi')) {
          this.id_oi = localStorage.getItem('id_oi')
          localStorage.removeItem('id_oi')
          this.getMontantTotal()
        }
      }
    }
  }
</script>
